// TODO: Clean up this mess!
.o-timeline {
    overflow: hidden;
    margin: 2em auto;
}

.o-timeline__container {
    position: relative;
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 2em 0;
}

.o-timeline__container::before {
    display: none;
    content: "";
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 18px;
    height: 100%;
    width: 2px;
    background: $white;
    @include media-breakpoint-up(md) {
        display: block;
    }
    transition: all, .25s;

}
.o-timeline__container::after{
    display: none;
    content: "";
    position: absolute;
    bottom: 0px;
    left: 4px;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    background: $white;
    z-index: 0;
    @include media-breakpoint-up(md) {
        display: block;
    }
    transition: all, .25s;


}

@include media-breakpoint-up(lg) {
    .o-timeline {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .o-timeline__container::before {
        left: 50%;
        margin-left: -2px;
    }
    .o-timeline__container::after {
        bottom: 0;
        height: 32px;
        left: 50%;
        margin-left: -16px;
    }

    .o-timeline__block {
        &--left {
            text-align: right;
        }
    }
}

.o-timeline__block {
    position: relative;
    margin: 2em 0;

    &:hover {
        .o-timeline__img {
            // background: $light-blue;
            transition: all .25s ease;
            transform: scale(1.2);

            span {
                // color: $white;
            }
        }
    }
}

.o-timeline__block:after {
    content: "";
    display: table;
    clear: both;
}

.o-timeline__block:first-child {
    margin-top: 0;
}

.o-timeline__block:last-child {
    margin-bottom: 0;
}

@include media-breakpoint-up(lg) {
    .o-timeline__block {
        margin: 4em 0;
    }
}

.o-timeline__img {
    display: none;
    position: absolute;
    top: -10px;
    left: -9px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: $white;
    justify-content: center;
    z-index: 1;

    transition: all .25s ease;

    span {
        font-weight: bold;
        color: $brand-blue;
        font-size: 1.5rem;
        line-height: 2;
    }
    @include media-breakpoint-up(md) {
        display: flex;
    }
    @include media-breakpoint-up(lg) {
        left: 0;
        width: 56px;
        height: 56px;
        left: 50%;
        margin-left: -28px;
    }

}

.o-timeline__content {
    position: relative;
    color: $white;
    margin-bottom: 3rem;
    @include media-breakpoint-up(md) {
        margin-left: 85px;
    }
    @include media-breakpoint-up(lg) {
        margin-left: 0;
        width: 45%;
    }

    h3 {
        color: $white;
    }

}

.o-timeline__content:after {
    content: "";
    display: table;
    clear: both;
}


.o-timeline__content p {
    margin: 1em 0;
    line-height: 1.6;
}

.o-timeline__date {
    display: inline-block;
}

@include media-breakpoint-up(lg) {
    .o-timeline__block:nth-child(even) .o-timeline__content {
        float: right;
    }

    .o-timeline__block:nth-child(even) .o-timeline__content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: white;
    }

    .o-timeline__block:nth-child(even) .o-timeline__read-more {
        float: right;
    }

    .o-timeline__block:nth-child(even) .o-timeline__date {
        left: auto;
        right: 122%;
        text-align: right;
    }
}
