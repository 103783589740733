.a-logo {
    svg {
        width: 100%;
        height: 100%;
    }

    &__leu {
        fill: $blue;
    }

    &__drop {
        fill: $brand-red;
    }

    &__tagline {
        fill: $black;
    }

    &--mono-negative {
        .a-logo__leu {
            fill: $light-gray;
        }

        .a-logo__drop {
            fill: $light-gray;
        }

        .a-logo__tagline {
            fill: $light-gray;
        }
    }

    &--mono-positive {
        .a-logo__leu {
            fill: $black;
        }

        .a-logo__drop {
            fill: $black;
        }

        .a-logo__tagline {
            fill: $black;
        }
    }
}
