.m-pricing-table {
    @include media-breakpoint-up(lg) {
        font-size: .75em;
    }
    @include media-breakpoint-up(xl) {
        font-size: 1em;
    }

    .row {
        align-items: flex-end;
        justify-content: center;
    }

    &__item {
        @include mdElevation(6);
        @extend .card;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-color: lighten($gray, 41%);
        border-radius: $border-radius;
        margin-bottom: 2rem;

        &-recommendation {
            position: absolute;
            display: none;
            right: 0;
            @include media-breakpoint-up(sm) {
                width: 7rem;
                display: block;
            }
            @include media-breakpoint-up(lg) {
                width: 4.5rem;
                display: block;
            }
            @include media-breakpoint-up(xl) {
                width: 5rem;
                display: block;
            }


            polygon {
                fill: $light-blue !important;
            }
            text {
                fill: $white !important;
            }
        }

        &-label {
            // background: $light-gray;
            text-align: center;
            color: $gray;
            padding: 0 1.25rem;
        }

        &-header {
            @extend .card-header;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 2rem 2.5rem 2.25rem 2.5rem;
            background: none;
            border: 0;

            img {
                @extend .img-fluid;
              max-width: 13rem;
            }
        }

        &-body {
            @extend .card-body;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding-bottom: 2.5rem;

            .a-list {
                margin: 0;
            }

            mark {
                padding: .25em;
                margin-right: .25em;
            }
            &-top {

            }
            &-center {
                min-height: 180px;
                align-items: center;
                justify-content: center;
            }
            &-bottom {
                min-height: 80px;
            }
            &-item {
                padding:0.75rem;
            }
            &-container {
                > :nth-child(even) {
                    @extend .bg-lighter;
                }
            }
            .a-switch {
                label {
                    margin-bottom:0;
                }
            }
            .custom-radio {
                text-align:center;
                padding-left:0;
            }
        }

        &-footer {
            @extend .card-footer;
            text-align: center;
            border-radius: 0 0 $border-radius $border-radius;
            padding: 2rem 1.25rem 1.25rem;
            border: 0;
            background: $light-gray;
        }

        .a-icon-info {
            @extend .small;
            color: $brand-blue;
        }

        &-price {
            font-size: 3.25em;
            color: $light-blue;
            margin-bottom: 1.5rem;
            @include media-breakpoint-down(md) {
                font-size: 3em;
            }

        }

        &--no-radius-left {
          @include media-breakpoint-up(md) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 1px solid $light-gray;
          }

        }

      &--small {
        @extend .small;
      }
    }

    // Table extension

    .a-table {
        border-top: 1px solid $light-gray;
        td {
            padding: .5rem;
        }
    }
}
