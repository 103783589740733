
// https://github.com/prestopasto/SCSS-Material-Shadows

/**
 * Shadow colors.
 */
$KEY_UMBRA: rgba(0, 0, 0, 0.2);
$KEY_PENUMBRA: rgba(0, 0, 0, 0.14);
$AMBIENT: rgba(0, 0, 0, 0.12);

/**
 * Animation durations.
 */
$MIN_ANIMATION_LENGTH: 250ms;
$MAX_ANIMATION_LENGTH: 400ms;

/**
 * The hand-designed reference shadow sets.
 */

$REFERENCE_SHADOWS: (
  ( elevation: 0,
    shadows: (
      (0, 0, 0, 0, $KEY_UMBRA),
      (0, 0, 0, 0, $KEY_PENUMBRA),
      (0, 0, 0, 0, $AMBIENT),
    )
  ),
  ( elevation: 2,
    shadows: (
      (0, 3, 1, -2, $KEY_UMBRA),
      (0, 2, 2, 0, $KEY_PENUMBRA),
      (0, 1, 5, 0, $AMBIENT),
    )
  ),
  ( elevation: 3,
    shadows: (
      (0, 3, 3, -2, $KEY_UMBRA),
      (0, 3, 4, 0, $KEY_PENUMBRA),
      (0, 1, 8, 0, $AMBIENT),
    )
  ),
  ( elevation: 4,
    shadows: (
      (0, 2, 4, -1, $KEY_UMBRA),
      (0, 4, 5, 0, $KEY_PENUMBRA),
      (0, 1, 10, 0, $AMBIENT),
    )
  ),
  ( elevation: 6,
    shadows: (
      (0, 3, 5, -1, $KEY_UMBRA),
      (0, 6, 10, 0, $KEY_PENUMBRA),
      (0, 1, 18, 0, $AMBIENT),
    )
  ),
  ( elevation: 8,
    shadows: (
      (0, 5, 5, -3, $KEY_UMBRA),
      (0, 8, 10, 1, $KEY_PENUMBRA),
      (0, 3, 14, 2, $AMBIENT),
    )
  ),
  ( elevation: 16,
    shadows: (
      (0, 8, 10, -5, $KEY_UMBRA),
      (0, 16, 24, 2, $KEY_PENUMBRA),
      (0, 6, 30, 5, $AMBIENT),
    )
  )
);

/**
* Returns the two reference shadows whose elevations bound the specified
* elevation. In the case where the supplied elevation exceeds the maximum
 * reference elevation, the last two reference shadows are returned.
 */
@function findBoundingShadowSets($elevation) {
  @if $elevation < 0 {
    @warn "Elevation is less than zero";
  }
  @for $i from 1 to length($REFERENCE_SHADOWS) {
    $lower: nth($REFERENCE_SHADOWS, $i);
    $upper: nth($REFERENCE_SHADOWS, $i + 1);
    @if (map-get($lower, "elevation") <=$elevation) and (map-get($upper, "elevation") > $elevation) {
      @return ($lower, $upper);
    }
  }
  $lower: nth( $REFERENCE_SHADOWS, length($REFERENCE_SHADOWS) - 1);
  $upper: nth( $REFERENCE_SHADOWS, length($REFERENCE_SHADOWS));
  @return ($lower, $upper);
}


/**
 * Performs linear interpolation between values a and b. Returns the value
 * between a and b proportional to x (when x is between 0 and 1. When x is
 * outside this range, the return value is a linear extrapolation).
 */

@function lerp($x, $a, $b) {
  @return $a + $x * ($b - $a);
}


/**
 * Performs linear interpolation between shadows by interpolating each property
 * individually. Returns the value between shadow1 and shadow2 proportional to x
 * (when x is between 0 and 1. When x is outside this range, the return value is
 * a linear extrapolation).
 */

@function lerpShadow($x, $shadow1, $shadow2) {
  // Round all parameters, as shadow definitions do not support subpixels
  $newX: round(lerp($x, nth($shadow1, 1), nth($shadow2, 1)))+0px;
  $newY: round(lerp($x, nth($shadow1, 2), nth($shadow2, 2)))+0px;
  $newBlur: round(lerp($x, nth($shadow1, 3), nth($shadow2, 3)))+0px;
  $newSpread: round(lerp($x, nth($shadow1, 4), nth($shadow2, 4)))+0px;
  $newColor: nth($shadow1, 5); // No need to lerp the shadow color
  @return ($newX $newY $newBlur $newSpread $newColor);
}


/**
 * Calculates the set of shadows at a given elevation.
 */

@function calculateShadowSetAtDepth($elevation) {
  $bounds: findBoundingShadowSets($elevation);
  $min: nth($bounds, 1);
  $max: nth($bounds, 2);
  $x: ($elevation - map-get($min, "elevation")) / (map-get($max, "elevation") - map-get($min, "elevation"));
  $elevationShadows: ();
  @for $i from 1 to length(map-get($min, "shadows")) + 1 {
    $newShadow: lerpShadow($x, nth(map-get($min, "shadows"), $i), nth(map-get($max, "shadows"), $i));
    $elevationShadows: append($elevationShadows, $newShadow, comma);
  }
  @return $elevationShadows;
}

@mixin mdElevation($elevation1, $elevation2: null, $state: null) {
  @if $elevation1==0 {
    box-shadow: none !important;
  }
  @else {
    box-shadow: calculateShadowSetAtDepth($elevation1);
  }

  @if $elevation2 != null {
     @include mdElevationTransition($elevation2 -$elevation2);
    &:#{$state} {
      @if $elevation1==0 {
        box-shadow: none !important;
      }
      @else {
        box-shadow: calculateShadowSetAtDepth($elevation2);
      }
    }
  }
}

@mixin mdElevationChange($lowerElevation, $upperElevation, $state) {
  @include mdElevation($lowerElevation);
  //$deltaElevation: $upperElevation -$lowerElevation;
  @include mdElevationTransition($upperElevation -$lowerElevation);
  &#{$state} {
    @include mdElevation($upperElevation);
  }
}

@mixin mdElevationTransition($elevationChange: 6, $easing: cubic-bezier(.4, 0, .2, 1)) {
  $duration: lerp($elevationChange/24, $MIN_ANIMATION_LENGTH, $MAX_ANIMATION_LENGTH);
  transition: box-shadow $duration $easing;
}

@for $zValue from 0 through 24 {
  .md-elevation-z#{$zValue} {
    @include mdElevation($zValue);
  }
}
