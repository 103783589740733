.a-list {
    counter-reset: foo;
    display: table;

        &--checkmarks {
            @extend .list-unstyled;
            .a-list__item {
                font-size: 1em;
                line-height: 1.25;
                counter-increment: foo;
                display: table-row;

                &:before {
                    content: "\6c";
                    position: relative;
                    display: table-cell;
                    text-align: right;
                    padding-right: .3em;
                    top: .25em;
                    font-family: 'leu-icons';
                    font-size: 1.5em;
                    color: $light-blue;
                }

            }

            li {
                @extend .a-list__item;
            }
        }
}

ul {
    padding-left: 1em;
}