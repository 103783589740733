.m-group-navigation-mobile {
  margin-top: 1rem;

  &__inner {
    //margin-left: -18px;
    //margin-right: -18px;
    background: $light-gray;
  }

  &__item {
    border-bottom: 1px solid $white;

    &-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .5rem .75rem;

      &.collapsed {
        & > i {
          &:before {
            transition: all .15s;
            transform: rotate(90deg);
          }
        }
      }

      & > i {
        font-size: .75rem;
        line-height: 1;
        &:before {
          transition: all .3s;
          transform: rotate(270deg);
        }
      }



    }

    &-label {
      display: flex;
      align-items: center;
      & > i {
        display: flex;
        margin-right: .5rem;
      }

      & > span {
        //margin-top: -2px;
      }

    }


    &-level-1 {
      background: $blue;
      & > * {
      color: $white;
      }
      .m-group-navigation-mobile__item-link:hover {
        color: $white;
      }

    }

    &-level-2 {
      & > * {
        color: $black;
      }

    }

    &-level-3 {
      & > * {
        color: $black;
      }
      padding: .5rem 2.25rem;

      &:first-child {
        padding-top: 10px;
      }
      &:last-child {
        padding-bottom: 16px;
      }
    }

    &-level-4 {
      & > * {
        color: $black;
      }
      padding: .5rem 3rem;

    }

    .h5 > a  {
      color: $black;
    }

  }
  a[target^="_blank"]:after {
    margin-left: .5rem;
  }

}
