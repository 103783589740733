.m-progress-tracker {
    &__inner {
        display: flex;
        width: 100%;
        background: $light-gray;
    }

    &__item {
        display: flex;
        font-size: .7rem;
        //background: $white;
        padding: $spacer / 2 $spacer $spacer / 1.75 $spacer;
        width: 100%;
        //margin-right: 2px;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $white;
        //border-radius: $border-radius;


        &:first-child {
            //border-radius: $border-radius 0 0 $border-radius;
          border-left: 1px solid $white;
        }

        &:last-child {
            margin: 0;
            //border-radius: 0 $border-radius $border-radius 0;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1rem;
        }

        &-icon {
            font-size: .95rem;
            position: relative;
            top: .08rem;

            @include media-breakpoint-up(md) {
                margin-right: .55rem;
            }

            @include media-breakpoint-up(lg) {
                font-size: 1.25rem;
            }

            .a-icon:before {
                display: flex;
            }
        }

        &-sequence {
            font-weight: bold;
            @include media-breakpoint-up(md) {
                margin-right: .25rem;
            }
        }

        &-text {
            display: none;
            @include media-breakpoint-up(md) {
                display: inline;
            }
        }

        // states
        &.active {
            color: $white;
            background: $brand-blue;
        }

        &.done {
            color: $black;

            .m-progress-tracker__item-sequence {
                display: none;
                @include media-breakpoint-up(md) {
                    display: inline;
                }
            }

            .m-progress-tracker__item-icon {
                display: inline-block;
                color: $brand-blue;
            }
        }
    }
}
