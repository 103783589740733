.t-e4y, .t-e4y__wrapper {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

.t-e4y {
  display: flex;
  flex-direction: column;

  &__header, &__footer {
    flex: 0 0 auto;
  }

  &__header {
  }


  &__main {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }


  // Customize components


  .m-price-calculator {
    &__body {
      border-radius: $border-radius;
    }
    &__consumption {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(xl) {
        flex-direction: row;
      }

      &-buttons {
        display: flex;
        align-items: center;

        .btn-group {
          display: flex;
          border-bottom: 1px solid $medium-gray;
          padding-bottom: .375rem;
          padding-top: .375rem;
          flex-grow: 1;
          justify-content: center;
          @include media-breakpoint-up(xl) {
            padding-bottom: 0;
            border-bottom: 0;
            padding-top: 0;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .15rem .60rem;
            border: 0;
            border-radius: 0;
            background: transparent;
            flex-grow: 1;
            border: 0;
            border-right: 1px solid $medium-gray;
            min-width: 2.5rem;

            &:last-child {
              border-right: 0;
              @include media-breakpoint-up(xl) {
                border-right: 1px solid $medium-gray;
              }
            }

          }
        }

      }

    }

  }


}

// Page specific styles

#e4y-checkout-form{
  i.a-icon-info {
    font-size: .75rem;
    margin-left: .25rem;
  }
  .alert {
    i.a-icon-info {
      font-size: 2em;
    }
  }
  label {
    z-index: 0;
    i.a-icon-info {
      position: relative;
      top: 2px;
    }

  }
  .btn-group.btn-group-toggle {
              @include media-breakpoint-down(sm) {
                font-family: "Roboto Condensed";
                > .btn {
                  padding-left: .5rem;
                  padding-right: .5rem;
                }
              }
  }
}


