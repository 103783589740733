.o-newsletter-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__text {
        @include media-breakpoint-up(md) {
            flex-direction: row;
            max-width: 80%;
        }
        @include media-breakpoint-up(lg) {
            max-width: 60%;
        }
        @include media-breakpoint-up(xl) {
            max-width: 50%;
        }
        * {
            text-align: center;
            color: $white;
        }
        margin-bottom: 1rem;
    }

    &__heading {
        @extend .h3;
    }

    &__copy {
        margin: 0;
        @extend small;
        a {
            text-decoration: underline;
        }
    }

    &__form {
        width: 100%;
        display: flex;
        justify-content: center;
        .o-form {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @include media-breakpoint-up(md) {
                flex-direction: row;
                max-width: 80%;
            }
            @include media-breakpoint-up(lg) {
                max-width: 60%;
            }
            @include media-breakpoint-up(xl) {
                max-width: 50%;
            }
        }
    }

    &__input {
        margin-bottom: .5rem;
        flex-grow: 1;
        @include media-breakpoint-up(md) {
            margin-right: .5rem;
        }

    }

    &__submit {
        button {
            display: block;
            width: 100%;
        }

    }
}
