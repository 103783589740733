.m-carbon-calculator {
 @extend .m-price-calculator;

  &__header {
    @extend .m-price-calculator__header;
  }

  &__body {
    @extend .m-price-calculator__body;
  }

  &__heading {
    @extend .m-price-calculator__heading;
  }

  &__consumption {
    @extend .m-price-calculator__consumption;
    &-value {
      @extend .m-price-calculator__consumption-value;
      input#wohnflaeche {
       padding-right: 1.8rem;
      }
      input#jahresverbrauch {
        padding-right: 2.5rem;
      }
    }
  }x

  &__cta {
    @extend .m-price-calculator__cta;
  }
  .form-group__inner {
    //& > label {
    //  width: 100%;
    //  .a-icon-info {
    //   float: right;
    //  }
    //}
  }
  .form-control {
    height: auto;
  }

}
