
// font smoothing
body {
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

// remove spinner arrows
input[type=number]::-webkit-inner-spin-button,
   input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: none;
    margin: 0;
}
// Responsive typo
h1, .h1 {
    font-size: $h1-font-size*.8;
    @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
    }
}

.lead {
    font-size: $lead-font-size*.75;
    @include media-breakpoint-up(sm) {
        font-size: $lead-font-size;
    }
}

.tiny {
  font-size: 65%;
}

// Tooltip typograhy

.tooltip-inner{
 @extend .small;
}

strong {
  font-weight: bold;
}

.w-60 {
  width: 60% !important;
}

// No linebreaks

.no-wrap {
  white-space: nowrap;
}

// Flat sides helper

.flat-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.flat-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.flat-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.flat-left {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.bg-lighter {
  background-color: $lighter-gray;
}

.modal-open {
  overflow: auto !important;
  padding-right: 0 !important;
}

// Tooltip border-radius
// Move into own component sometime

.tooltip-inner {
  border-radius: $border-radius;
  text-align: left;
}
