.m-content-navigation {
    @include mdElevation(4);
    border-radius: $border-radius;
    background: $white;
    &__inner {
        display: flex;
        flex-direction: column;

    }

    &__item {
        cursor: pointer;
        display: block;
        color: $black;
        padding: .75rem 1rem;
        transition: all .25s ease;
        border-bottom: 1px solid $light-gray;

        &:hover {
            color: $brand-red;
            transition: all .25s ease;
        }

        &--main {
            border-bottom: 1px solid $light-gray;
        }

        &--first {
            border-radius: $border-radius $border-radius 0 0;
        }

        &--last {
            border-radius: 0 0 $border-radius $border-radius;
            border-bottom: 0;
        }

        &--sub {
            background: $white;
            color: $light-blue;

            &:hover {
                color: $white;
                transition: all .25s ease;
            }
            &.active, &:active {
                color: $brand-blue;
            }

        }

        &-wrapper {
            &:not(.collapsed) {
                @include mdElevation(2);
                border-radius: $border-radius;
                z-index: 2;

                .m-content-navigation__item--main {

                    // box-shadow: 0px -4px 14px -2px rgba(0,0,0,0.41);
                    background: $brand-blue !important;
                    color: $white !important;
                    // box-shadow: 0px 0 0 1px $light-blue;
                    border-bottom: 2px solid $light-blue !important;
                    z-index: 1 !important;
                    border-bottom: 0;

                    &:hover {
                        color: $white !important;
                    }

                    &.m-content-navigation__item--first {
                        border-radius: $border-radius $border-radius 0 0;
                    }

                    &.m-content-navigation__item--last {
                        border-radius: 0;
                    }

                }

                .m-content-navigation__item--sub {
                    &:hover {
                        color: $brand-red;
                    }
                }

                .list-group-item {
                    background: $light-gray;
                    border-bottom: 1px solid $white;
                    color: $black;

                    &:hover {
                        color: $brand-red;
                        z-index: 0;
                    }
                }
            }
        }

    }

    &--blue {
        background: $light-blue !important;

        .m-content-navigation__item--main {
            color: $white;

            &:hover {
                background: $brand-blue;
            }
        }
    }

    .p-inner p {
        color: $black !important;
    }

}
