body {
  //padding-top: 7.1rem;
  transition: all .25s ease-in-out;
  &.is-sticky {

    .o-navbar {
      @extend .small;
      transition: all .25s ease-in-out;
      min-height: 0;
      padding: .25rem 1rem .6rem 1rem;
      box-shadow: 0 0 30px -10px #000;

      .o-navbar__item {
        font-size: 1rem;
        transition: all .25s ease-in-out;

      }

      .o-navbar__brand {
        img {
          max-height: 2rem;
          transition: all .25s ease-in-out;

        }
      }

      .o-navbar__hotline {
        margin-top: .7rem;
      }

      .o-navbar__hotline-icon {
        //font-size: 2.4em;

      }
      .o-navbar__right {
        margin-top: .5rem;
      }

    }

  }

  @include media-breakpoint-down(md) {
    //padding-top: 62px;
  }
}

.o-navbar {
  @extend .navbar;
  @extend .navbar-expand-md;
  @extend .navbar-light;
  background: $white;
  z-index: 4;
  min-height: 7.1rem;
  transition: all .25s ease-in-out;
  //position: fixed;
  top: 0;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1.4rem;

  @include media-breakpoint-down(md) {
    min-height: 0;
    padding: .25rem 1rem .6rem 1rem;
    box-shadow: 0 0 30px -10px #000;
  }

  @include media-breakpoint-down(sm) {
    display: block !important;
  }

  &.fixed-top {
    margin-top: 42px;
    transition: margin-top .25s;


    &.scrolled {
    margin-top: 0;
  }

  }


  &__inner {
    @extend .container;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__cta {
    font-weight: bold;
  }

  &__brand {
    @extend .navbar-brand;
    padding: .35rem 0 .2rem;
    margin-right: 2.25rem;

    @include media-breakpoint-down(lg) {
      margin-right: 1.5rem;
    }

    img {
      max-height: 3.5rem;
      max-width: 10rem;
      transition: all .25s ease-in-out;
    }
  }

  &__right {
    margin-left: auto;
    display: flex;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;

    }
    @include media-breakpoint-up(lg) {
      margin-left: 1rem;
    }

  }

  &__item {
    @extend .nav-item;
    transition: all .25s ease-in-out;
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
      margin-top: .85rem;
      font-size: .934rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }

    &-link {
      @extend .nav-link;
      margin-right: .5rem;
      white-space: nowrap;

        &:after {
          content: "";
          display: block;
          height: 3px;
          background: transparent;
          width: 1%;
          transition: all .15s;
          margin: 0 auto;
          margin-top: .65rem;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        &.dropdown-toggle i {
          transition: all .15s;
          font-size: .75rem;
          color: $black;
        }

      i.a-icon-sign-in {
        font-size: 1rem;
        position: relative;
        top: 2px;
        color: $black;
      }

      &:hover, &:focus, &.active {
        color: $black !important;
        text-decoration: none;

        &:after {
         background: $black;
          width: 100%;
        }

        &.dropdown-toggle i {
          color: $black;
        }

      }
    }
    &-icon {
      margin-top:-1px;
    }
    .dropdown-menu {
      top: -11px !important;
      left: 5px !important;
      border: none;
      //border-top: 3px solid $black;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      @include mdElevation(2);
      padding: 0;
      @include media-breakpoint-down(md) {
        top: -10px !important;
        left: 7px !important;
      }
      @include media-breakpoint-down(sm) {
        left: 0 !important;
      }

      .dropdown-item {
        padding: .5rem 1rem;
        @include media-breakpoint-down(sm) {
        padding-left: 0;
        }
          &:first-child {
          padding-top: 1rem;
        }
        &:last-child {
          padding-bottom: 1rem;
          @include media-breakpoint-down(sm) {
            padding-bottom: 0;
          }

        }
        &:hover {
          background: transparent;
          color: $brand-red
        }
      }

      &.show {
        @include media-breakpoint-down(sm) {
          display: block !important;
          position: relative !important;
          transform: none !important;
          box-shadow: none;
          border: none;
        }

      }
    }
  }

  &__hotline {
    line-height: .75;
    margin-top: .5rem;
    margin-left: auto;
    font-size: .75rem;

    @include media-breakpoint-up(xl) {
      margin-top: .15rem;
      font-size: 1rem;
    }

    &-icon {
      color: $black;
      font-size: 2.2em;
      margin-right: .1em;
      position: relative;
      top: -.1em;
    }

    &-number {
      font-size: 1.25em;
      color: $black;
      white-space: nowrap;
      // font-weight: bold;
      text-decoration: none;
      margin-bottom: .35rem;
      letter-spacing: -.018rem;
    }

    &-description {
      font-size: .65em;
      color: $black;
      white-space: nowrap;
    }
  }

  &__toggle {
    @extend .navbar-toggler;
    background: transparent;
    font-size: 2rem;
    padding: 0;
    border: none;
    color: $light-blue;

    & > .a-icon-menu {
      color: $brand-blue;
    }

  }

  .collapse.show,
  .collapsing {
    .o-navbar__item {
      padding: .75rem 0;
      border-top: 1px solid $light-gray;

      &:first-child {
        padding-top: 1rem;
        border: 0;
      }

      &:last-child {
        padding-bottom: 1rem;
      }
    }

    .o-navbar__hotline {
      font-size: 1rem;
      padding: 1rem .5rem .5rem .5rem;
      background: $light-gray;
      margin-bottom: .5rem;
      border-radius: $border-radius;

    }
  }

  &--small {
    position: relative;
    min-height: 0;
    padding: .25rem 1rem .6rem 1rem;
    .o-navbar__hotline {
      margin-top: .9rem;
    }

    &.is-sticky {
      box-shadow: none !important;
    }
  }

  .navbar-collapse {
  }

  .dropdown {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: inline-block;
      width: auto;
      top: .2rem;

    }
    @include media-breakpoint-up(lg) {
      top: -.2rem;

    }

    .a-button {
      display: block;
      width: 100%;
      @include media-breakpoint-up(md) {
        display: inline-block;
        width: auto;

      }

      &:active, &:focus {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
      }
    }
    .dropdown-menu {
      padding: 0;
      margin: 0;
      background: $white;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;


      .dropdown-item {
        @extend .small;
        color: $black;
        padding: .5rem 1rem;
        border-bottom: 1px solid $light-gray;

        &:last-child {
          border: 0;
          border-radius: 0 0 $border-radius $border-radius;
        }

        &::after {
          display: none;
        }

        i {
          position: relative;
          top: 2px;
          margin-left: .5em;
        }

        &:hover {
          color: $brand-red;
          text-decoration: none;
          background: $white;
        }

      }
    }
  }
  .navbar-collapse.collapse.show {
    height: calc(100vh - 56px);
    overflow: scroll;
  }

}

header {
  box-shadow: none;
}

header.fixed-shadow {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
}

