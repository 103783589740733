@font-face {
    font-family: "leu-icons";
    src: url("#{$font-paths}leu-icons.eot");
    src: url("#{$font-paths}leu-icons.eot?#iefix") format("embedded-opentype"), url("#{$font-paths}leu-icons.woff") format("woff"), url("#{$font-paths}leu-icons.ttf") format("truetype"), url("#{$font-paths}leu-icons.svg#leu-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "leu-icons" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="a-icon-"]:before,
[class*=" a-icon-"]:before {
    display: inline-block;
    font-family: "leu-icons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.a-icon-strom:before {
  content: "\61";
}
.a-icon-pellets:before {
  content: "\62";
}
.a-icon-heizoel:before {
  content: "\63";
}
.a-icon-tankschutz:before {
  content: "\64";
}
.a-icon-tankstellen:before {
  content: "\65";
}
.a-icon-kraftstoffe:before {
  content: "\66";
}
.a-icon-erdgas:before {
  content: "\67";
}
.a-icon-schmierstoffe:before {
  content: "\68";
}
.a-icon-external-link:before {
  content: "\69";
}
.a-icon-chevron-left:before {
    transform: rotate(180deg);
    content: "\6b";
}
.a-icon-chevron-right:before {
  content: "\6b";
}
.a-icon-check:before {
  content: "\6c";
}
.a-icon-chatboxes:before {
  content: "\6e";
}
.a-icon-envelope:before {
  content: "\6d";
}
.a-icon-pointer:before {
  content: "\6f";
}
.a-icon-file:before {
  content: "\70";
}
.a-icon-instagram:before {
  content: "\73";
}
.a-icon-caret-left:before {
    transform: rotate(180deg);
    content: "\74";
}
.a-icon-caret-down:before {
    transform: rotate(90deg);
    content: "\74";
}
.a-icon-caret-right:before {
  content: "\74";
}
.a-icon-facebook:before {
  content: "\72";
}
.a-icon-phone:before {
  content: "\6a";
}
.a-icon-menu:before {
  content: "\71";
}
.a-icon-close:before {
  content: "\75";
}
.a-icon-info:before {
  content: "\76";
}
.a-icon-sitemap:before {
  content: "\77";
}
.a-icon-youtube:before {
  content: "\41";
}
.a-icon-fax:before {
  content: "\78";
}
.a-icon-mobile:before {
  content: "\79";
}
.a-icon-alert:before {
  content: "\7a";
}
.a-icon-phone-alt:before {
  content: "\42";
}
.a-icon-circle-1:before {
  content: "\31";
}
.a-icon-circle-2:before {
  content: "\32";
}
.a-icon-circle-3:before {
  content: "\33";
}
.a-icon-circle-4:before {
  content: "\34";
}
.a-icon-circle-5:before {
  content: "\35";
}
.a-icon-circle-6:before {
  content: "\36";
}
.a-icon-circle-7:before {
  content: "\37";
}
.a-icon-circle-8:before {
  content: "\38";
}
.a-icon-circle-9:before {
  content: "\39";
}
.a-icon-circle-10:before {
  content: "\30";
}
.a-icon-circle-check:before {
  content: "\4d";
}
.a-icon-radio-off:before {
  content: "\43";
}
.a-icon-radio-on:before {
  content: "\44";
}
.a-icon-user:before {
  content: "\45";
}
.a-icon-calculator:before {
  content: "\47";
}
.a-icon-trophy:before {
  content: "\46";
}
.a-icon-medal:before {
  content: "\48";
}
.a-icon-leaf:before {
  content: "\49";
}
.a-icon-sign-in:before {
  content: "\4a";
}
.a-icon-lock:before {
  content: "\4b";
}
