.card-footer {
    background: $light-gray;
}
.m-card {

    // elements

    .card {
        @extend .card;
        border: 0;
        @include mdElevation(6);
        border-radius: $border-radius;
    }

    .card-header {}

    .card-title {
        color: $headings-color !important;
    }

    .card-body {}

    .card-text {}

    .card-footer {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .card-img-top {
        border-radius: $border-radius $border-radius 0 0;
    }
    // color modifiers

    &--highlight {
        .card {
            @extend .bg-primary;
            @extend .text-light;

            &-title {
                color: $white !important;
            }
        }

        .a-button {
            &:hover {
                color: $primary;
            }
        }

        hr {
            border-top: 1px solid rgba(255, 255, 255, 0.25);
        }

        .a-list--checkmarks {
            li {
                &:before {
                    color:$white;
                }
            }
        }
    }

    &--secondary {
        .card {
            @extend .bg-secondary;
            @extend .text-light;

            &-title {
                color: $white !important;
            }
        }

        .a-button {
            &:hover {
                color: $secondary;
            }
        }

        hr {
            border-top: 1px solid rgba(255, 255, 255, 0.25);
        }
    }

    &--success {
        .card {
            @extend .bg-success;
            @extend .text-light;

            &-title {
                color: $white !important;
            }
        }

        .a-button {
            &:hover {
                color: $success;
            }
        }

        hr {
            border-top: 1px solid rgba(255, 255, 255, 0.25);
        }
    }
    &--cta {
        .card {
            @extend .bg-cta;
            @extend .text-light;

            &-title {
                color: $white !important;
            }
        }

        .a-button {
            &:hover {
                color: $cta;
            }
        }

        hr {
            border-top: 1px solid rgba(255, 255, 255, 0.25);
        }
    }

    // layout modifiers

    &--horizontal {
        .card {
            align-items: center;

            &-img-wrapper {
                padding: 1.25rem 1.25rem 0;
                flex-grow: 1;
                min-width: 40%;

                img {
                    @extend .img-fluid;
                    border-radius: $border-radius;
                }
            }

            @include media-breakpoint-up(sm) {
                flex-direction: row;

                &-img-wrapper {
                    padding: 1.25rem 0 1.25rem 1.25rem;
                }

            }
        }
    }
    &--no-radius-right {
      .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    }
}
