.o-footer {
    &__top {
        &-seal {
            @extend .d-flex;
            align-items: center;
            justify-content: center;
            background: $light-gray;
            padding: 1rem;

            p {
                font-size: 1rem;
                color: $brand-blue;
                font-weight: bold;
                margin: 0;
            }
        }
    }

    &__heading {
        font-weight: bold;
        color: $dark-gray;
        @extend .h4;
    }

    &__list {
        @extend .list-unstyled;
        @extend .small;

        a {
            i {
                color: $light-blue;
                display: inline-block;
                position: relative;
                top: .1rem;
            }

            &:hover {
                i {
                    color: $light-red;
                }
            }

            &:after {
                display: none !important;
            }
        }
    }

    &__address {
        @extend .small;
    }

    &__logos {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        @include media-breakpoint-up(sm) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &__logo {
        width: 45%;
        margin-bottom: 1rem;

        &:last-child {
            flex-grow: 1;
            @include media-breakpoint-up(sm) {
                flex-grow: 0;
            }

            img.img-fluid {
                max-width: 45%;
                height: auto;
                @include media-breakpoint-up(sm) {
                    max-width: 100%;
                }
            }
        }
        @include media-breakpoint-up(sm) {
            width: 30%;
        }
    }

    &__main {
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
        }
        padding: 6rem 2rem;
        line-height: 2;

        .h5 {
            color: $primary;
        }

        i {
            @extend .d-none;
            @extend .d-md-inline;
            font-size: .8rem;
            color: $dark-blue;
        }
    }

    &__bottom {
        @extend .bg-primary;
        @extend .text-white;
        padding: .5rem .5rem .55rem .5rem;

        .row {
            @extend .d-flex;
            flex-wrap: nowrap;

            flex-direction: column;
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
        }

        &-left {
            width: 100%;
            .nav {
                font-size: 80%;
                flex-wrap: nowrap;
                justify-content: center;
                @include media-breakpoint-up(md) {
                    justify-content: flex-start;
                    font-size: 100%;
                    flex-wrap: wrap;
                }

            }
            .nav-item {
                text-align: center;
                // width: 100%;
                @include media-breakpoint-up(md) {
                    width: auto;
                }
            }
        }

        &-right {
            text-align: center;
            width: 100%;
            padding: .5rem 1rem;
            @include media-breakpoint-up(md) {
                text-align: right;
            }
        }

        a {
            color: $white;
            &:after {
                display: none !important;
            }
        }
    }

    a,
    h4 {}

    i {
        color: $light-blue;
    }

    //a:after {
        //display: none !important;
    //}

    a[href ^= "tel"] {
        color: $dark;
        &:before {
            display: none;
        }

    }
}

footer .o-section--no {
    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
    }
}

