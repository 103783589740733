.a-carbon-scale {
  background: linear-gradient(to right,#3f9980 0%,#53a042 11%,#9ac52d 22%,#b8d569 33%,#f8dd2e 44%,#f9bc5f 55%,#f6a323 66%,#ee6761 77%,#e83c32 88%,#bb2d25 100%);
  height: 50px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  border-radius: $border-radius;
  //filter: grayscale(50%);

  &__scale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    span {
      text-shadow: 0 1px 3px rgba(0, 0, 0, .85);
      display: block;
      text-align: center;
      font-size: .5rem;
      min-width: 2em;
      @include media-breakpoint-up(sm) {
        min-width: 3rem;
        font-size: .75rem;
      }
    }

    .line {
      width: 1px;
      height: 100%;
      background: rgba(255,255,255,.5);

      &:first-child, &:last-child {
        visibility: hidden;
      }

    }

  }

  &__container {
    position:relative;
    top:-50px;
    padding-left:calc(1.5rem - 12px);
    padding-right:calc(1.5rem - 12px);
    display: inline-block;
    width: 100%;
    height: 50px;
  }
  &__indicator {
    position:relative;
    left: 47.5%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 0 12px;
    border-color: $white transparent transparent transparent;
    line-height: 0;
    _border-color: #007bff #000000 #000000 #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  }

}
