.a-scrolltop {
    &__wrapper {
        @extend .fixed-bottom;
        @extend .d-flex;
        align-items: center;
        justify-content: flex-end;
        left: inherit;
    }
    @extend .btn;
    @extend .btn-light;
    @extend .btn-lg;
    @include mdElevation(4);
    width: 3rem;
    border-radius: $border-radius $border-radius 0 0;
    padding-bottom: .25rem;
    padding-top: .25rem;
    margin-right: 1rem;

    &:focus {
        box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    &:hover {
        background: $brand-blue;

        i {
            color: $light-gray;
        }
    }

    i {
        font-size: 1rem;
        width: auto;
        height: auto;
        color: $light-blue;

        &:before {
            transform: rotate(-90deg);
        }
    }
}
