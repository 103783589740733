.t-design-system {

    // layout and grid section
    // ------------
    code {
        @extend .small;
    }

    th {
        border-top: 0;
    }

    #grid-demo {
        .row {
            [class*=col-] {
                margin-bottom: $spacer * 2;

                .inner {
                    min-height: 5rem;
                    padding: .5rem 1rem;
                    background: $light-gray;
                }
            }

            &:last-child {
                [class*=col-]:last-child {
                    margin-bottom: 0;
                }
            }

        }
    }

    // color section
    // ------------

    #color-swatches {
        .card {
            margin-bottom: $spacer * 2;
        }

        .card-color {
            min-height: 5rem;
            @include media-breakpoint-up(lg) {
                min-height: 8rem;
            }

            &--brand-blue {
                background: $brand-blue;
            }

            &--light-blue {
                background: $light-blue;
            }

            &--dark-blue {
                background: $dark-blue;
            }

            &--brand-red {
                background: $brand-red;
            }

            &--light-red {
                background: $light-red;
            }

            &--dark-red {
                background: $dark-red;
            }

            &--gray {
                background: $gray;
            }

            &--light-gray {
                background: $light-gray;
            }

            &--dark-gray {
                background: $dark-gray;
            }

            &--gradient-blue {
                background: $gradient-blue;
            }

            &--gradient-red {
                background: $gradient-red;
            }

            &--gradient-gray {
                background: $gradient-gray;
            }
        }
    }

    // icon section
    // ------------

    #icon-demo {
        .card {
            border: 0;
            margin-bottom: 2rem;

            i {
                font-size: 2rem;
                color: $light-blue;
            }

            &-body {
                padding: 0;
            }
        }
    }

    // logo section
    // ------------

    #logo-demo {
        .card {
            margin-bottom: 2rem;

            &-body {
                padding: 4rem;
            }

            &__footer {}

            &--black {
                .card-body {
                    background: $black;
                }
            }

            &--gradient {
                .card-body {
                    background: $gradient-blue;
                }
            }

            &--gray {
                .card-body {
                    background: $gray;
                }
            }

            &--light-gray {
                .card-body {
                    background: $light-gray;
                }
            }

            &--dark-gray {
                .card-body {
                    background: $dark-gray;
                }
            }

            i {
                font-size: 3rem;
                color: $light-blue;
            }

        }
    }

    // button section
    // ------------

    #button-demo {
        .card {
            margin-bottom: 2rem;

            &-body {
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                padding: 2rem 1rem;
                min-height: 12rem;
                flex-direction: column;
            }

            &__footer {}

            &--black {
                .card-body {
                    background: $black;
                }
            }

            &--gradient {
                .card-body {
                    background: $gradient-blue;
                }
            }

            &--gray {
                .card-body {
                    background: $gray;
                }
            }
        }

    }
    // components
    // ----------

    .m-price-calculator__demo-wrapper {
        background: $gradient-blue;
    }

}
