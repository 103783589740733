.m-pagination {
    &__list {
        @extend .pagination;
        @extend .pagination-lg;
        justify-content: center;
        margin: 0;

        &-item {
            @extend .page-item;
            &.active {
                a {
                    border-radius: $border-radius;
                }

            }

            // &:first-child {
            //     a {
            //         border-radius: $border-radius 0 0 $border-radius;
            //     }
            // }
            //
            // &:last-child {
            //     a {
            //         border-radius: 0 $border-radius $border-radius 0;
            //     }
            // }

            a {
                @extend .page-link;
            }
        }
    }
}
