// Component Settings

$o-section-whitespace: (
    xl: 13rem,
    lg: 8rem,
    md: 5rem,
    sm: 2rem,
    xs: 1rem,
    no: 0
);

$o-section-backgrounds: (
    default: $white,
    highlight: $light-blue,
    light: $light-gray,
    dark: $gray,
    transparent: transparent,
    special: $gradient-blue,
    gradient-gray: $gradient-gray,
    gradient-gray-light: $gradient-gray-light
);

.o-section {
    overflow: auto;

}
// Element characteristics
.o-section__inner {
    @extend .container;
}

// Whitespace modifiers

@each $space, $value in $o-section-whitespace {
    .o-section--#{$space} {
        padding-top: $value;
        padding-bottom: $value * 1.1;
        @include media-breakpoint-down(sm) {
            padding-top: $grid-gutter-width;
            padding-bottom: $grid-gutter-width;
        }

    }
}

// Background modifiers

@each $background, $value in $o-section-backgrounds {
    .o-section--#{$background} {
        background: $value;
    }
}


// Border modifiers

@each $background, $value in $o-section-backgrounds {
    .o-section--border-#{$background} {
        border-bottom: 1px solid $value;
    }
}

// Layout modifiers

.o-section {
    &--fullheight {
        display: flex;
        height: 100%;
        @media only screen and (max-height: 650px) {
        padding: $spacer * 2 0 $spacer * 2 0;
        }
        &-top {
        align-items: top;

        }
        &-center {
        align-items: center;

        }

    }

    &--center-horizontal {
        .o-section__inner {
            display: flex;
            justify-content: center;
        }
    }

    &--fullwidth {
      width: 100%;
        .o-section__inner {
            padding: 0;
            max-width: 100%;
        }
    }

    &--radial {
        background-image: radial-gradient(at bottom, rgba(60, 101, 171, 0.4), rgba(60, 101, 171, 0) 50%)
    }
}

.o-section--special {

& > h1, h2, h3, h4, h5, h6 {
    color: $white;
}

p, ul {
    color: $white;
}

}



