@charset 'utf-8';

@import "../../../node_modules/bootstrap/scss/functions";
@import "brand";

// Theme colors
$brand-blue:    #001E60;
$light-blue: #003297;
$dark-blue: #001943;

@import "settings";

@import "bootstrap";
@import "mixins";
@import "fonts";
@import "icons";
@import 'forms';
@import '../../components/components';
@import 'shame';
@import 'autocomplete';

@import '../../../node_modules/jquery-offcanvas/dist/jquery.offcanvas.min';

@import "typo3/app";

.a-button--cta-solid,
.m-code-input__submit {
  background: #b5bd00;
  border: 1px solid #b5bd00;
  color: #fff;
}

.m-price-calculator__heading .a-icon,
.m-carbon-calculator__heading .a-icon,
.m-price-calculator__heading,
.m-carbon-calculator__heading {
  color:$white;
}