// Theme colors
$brand-blue:    #006AB3;
$light-blue:    #1794EB;
$dark-blue:     #00487A;

$brand-red:     #C01513;
$light-red:     #E5302E;
$dark-red:      #990908;

$light-green:   #19AA00;
$dark-green:    #007323;

$gray:          #7F7F7F;
$medium-gray:   lighten($gray, 30%);
$lighter-gray:  rgba(64, 64, 64, 0.05);
$light-gray:    #efefef;
$dark-gray:     #404040;

$blue:    #006AB3;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #C01513;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;