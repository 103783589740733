.o-social-panel {
display: flex;
justify-content: space-between;
width: 25%;
min-width: 300px;

&__item {

    i {
        display: flex;
        color: $dark-gray;
        font-size: 1.75rem;
        transition: all, .25s;
        &:hover {
            transition: all, .25s;
            color: $brand-red;
        }
    }
}
&__divider {
    width: 1px;
    background: $white;
}
}
