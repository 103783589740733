.m-group-navigation {
    @extend .d-none;
    @extend .d-md-block;
    z-index: 5;
    position: relative;

    background: $light-gray;

    &__inner {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @extend .small;
    }

    &__item {
        @extend .nav-item;
        &-special {
            span {
                color: $black;

            }
            &:hover {
                span {
                    color: $brand-red;
                }
            }
            .dropdown-item:not(.dropdown-heading) {
                padding-left: 1.5rem !important;
            }

        }


        &.dropdown.show {
            background: $brand-blue;

            .m-group-navigation__link {
                color: $white;

                span {
                    color: $white;
                }
                i {
                    color: $white;
                }
                &:hover {
                    i {
                        &.a-icon-sitemap {
                            color: $white;
                        }
                    }
                    span {
                        color: $white;
                    }
                }


            }
        }
    }

    &__link {
        border: 0;
        color: $dark-gray;
        border-right: 1px solid white;

    }

    &__primary {
        @extend .nav;
        @extend .nav-tabs;
        @extend .nav-justified;
        flex-grow: 1;
        border: 0;

        .m-group-navigation__item {
            margin-bottom: 0;
        }

        .m-group-navigation__link {
            display: flex;
            align-items: center;
            padding: .65rem .5rem .4rem;
            justify-content: center;
            font-size: .6rem;
            border: 0;
            border-right: 1px solid $white;
            background: transparent;
            white-space: nowrap;
            @extend .flex-column;
            @extend .flex-lg-row;

            @include media-breakpoint-up(xl) {
                font-size: .75rem;
            }

            &.active {
                border-right: transparent;
                background: $almost-white;
            }

            i {
                font-size: .85rem;
                margin-right: .25rem;

                &.a-icon-sitemap {
                    color: $black;
                }
            }

            &:hover {
                i {
                    &.a-icon-sitemap {
                        color: $brand-red;
                    }
                }
            }

            span {
                margin-bottom: .175rem;
            }
        }

    }

    &__contact {
        @extend .d-none;
        @extend .d-lg-flex;
        align-items: stretch;

        i {
            font-size: 1rem;
            margin-right: .25rem;

        }

        .btn {
            display: flex;
            align-items: center;
            border-radius: 0;
        }
    }

    .dropdown-menu {
        // background-color: $light-gray;
        padding: 0;
        border: 0;
        margin: 0;
        min-width: 15vw;
        background: $light-gray;
        // left: 1px !important;
        border-top: 2px solid $light-blue;
        border-radius: 0 0 $border-radius $border-radius;
        @include mdElevation(1);
        font-size: .75rem;

        &-right {
            min-width: 21vw;

        }

        &--first {
            top: 0 !important;
            left: -5px !important;
        }

        .dropdown-heading {
            padding: 1em;
            border-bottom: 1px solid $white;
            color: $black;
            font-weight: bold;
        }

        .dropdown-item {
            color: $black;
            padding: .5rem 1rem;
            border-bottom: 1px solid $white;
            display: flex;
            &:after {
                font-size: .9em;
                margin-top: 1px;
                margin-left: .5em;
            }

            &:first-child {
                border-top: 1px solid $white;
            }

            &:last-child {
                border: 0;
                border-radius: 0 0 $border-radius $border-radius;
            }

            &:active {
                background: $light-gray;
            }

            &::after {
                //display: none;
            }

            i {
                position: relative;
                top: 2px;
                margin-left: .5em;
            }

            &:hover {
                color: $brand-red;
                text-decoration: none;
            }

        }

    }

    &--mobile {
    }
}

header {
    background: $white;
}
