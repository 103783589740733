.m-code-input {
    display: flex;
    justify-content: center;

    .o-form {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__form {
        width: 100%;
        @extend .form-inline;
        display: flex;
        justify-content: center;
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }

    }

    &__field {
        @extend .form-control;
        font-size: 1.5rem;
        padding: .375em .75em;
        max-width: 100%;
        text-align: center;
        margin-bottom: .5rem;
        flex-grow: 1;

        @include media-breakpoint-up(md) {
            margin: 0 .5rem 0 0;
        }
    }

    &__submit {
        @extend .a-button;
        @extend .a-button--cta-solid;
        @extend .a-button--lg;
        @extend .btn-block;
        @include media-breakpoint-up(md) {
            display: inline-block;
            width: auto;
        }
    }

    .form-control:invalid ~ .invalid-feedback i,
    .form-control:valid ~ .valid-feedback i {
        top: 1.2em;
        right: 1em;
        font-size: 1.25em;
        @include media-breakpoint-up(sm) {
            top: 1.65em;
            right: 1.5em;
        }

    }
}
