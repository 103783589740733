.a-radio-group {
  clear: left;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;

  label {
    align-items: center;
    background-color: $gray;
    color: $white;
    cursor: pointer;
    display: flex;
    float: left;
    margin: 0;
    margin-right: 1px;
    min-height: 2rem;
    padding: .5em 1em;
    transition: all .25s;

    &:first-child {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &:last-child {
      border-radius: 0 $border-radius $border-radius 0;
      margin: 0;
    }

    &:hover {
      background-color: $dark-gray;
    }

    &.checked {
      background-color: $primary;

      &:before {
        content: "\6c";
        font-family: "leu-icons";
        font-size: 0.9em;
        margin-right: 0.5em;
      }

      &:hover {
        background-color: $dark-blue;
      }

    }

    }

  input[type="radio"] {
    display: none;
  }

}

.custom-radio {
  > label {
    cursor:pointer;
  }
}