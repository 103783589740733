$font-paths: '../fonts/';

// Colors
html {
    font-size: 18px;
}
$white:         #fff;
$almost-white:  #fafafa; // *facepalm*
$gray-100:      #f8f9fa;
$gray-150:      darken($gray-100, 2.5%);
$gray-200:      #F5F5F5;
$gray-300:      #dee2e6;
$gray-400:      #ced4da;
$gray-500:      #adb5bd;
$gray-600:      #7F7F7F;
$gray-700:      #495057;
$gray-800:      #404040;
$gray-900:      #212529;
$black:         #404040;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$colors: ();
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
), $colors);


// Theme Gradients
$gradient-blue: radial-gradient(ellipse farthest-corner at center bottom, $light-blue 30%, $brand-blue 80%);
$gradient-red: radial-gradient(ellipse farthest-corner at center bottom, $light-red 30%, $brand-red 80%);
$gradient-gray: radial-gradient(ellipse farthest-corner at center bottom, darken($light-gray, 30%) 30%, $gray 80%);
$gradient-gray-light: radial-gradient(ellipse farthest-corner at center bottom, $light-gray 30%, lighten($gray, 30%) 80%);


$primary:       $light-blue;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$cta:           $red;
$light:         $gray-100;
$lighter:       $lighter-gray;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $gray,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "cta":        $cta,
  "gray":        $gray,
  "primary-light":  $light,

), $theme-colors);

$theme-color-interval:      8%;


// Options
$enable-caret:              false;
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Fonts
$font-family-sans-serif:      "Roboto", "Source Sans Pro", "droid-sans", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5!default;

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;


// Grid
$grid-columns:      12;
$grid-gutter-width: 2rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 820px,
  lg: 960px,
  xl: 1140px,
  hero: 1800px
);

$navbar-light-color:                $dark-gray;
$navbar-light-hover-color:          $brand-red;
$navbar-light-active-color:         $blue;
$navbar-light-toggler-border-color: rgba($black,.1);

$border-radius:               3px;
$border-radius-lg:            $border-radius + 1;
$border-radius-sm:            $border-radius - 1;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4),
  7: ($spacer * 6),
  gutter: ($grid-gutter-width),
  inner: ($spacer + .25)
);

$body-bg:                   $white;
$body-color:                $black;

$link-color:                theme-color("primary");
$link-decoration:           none;
$link-hover-color:          $red;
$link-hover-decoration:     none;


$paragraph-margin-bottom:   1rem;
$h1-font-size:                $font-size-base * 3;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;
// Responsive headings

$headings-margin-bottom:      ($spacer / 2);
$headings-font-family:        "Roboto Condensed";
$headings-font-weight:           700;
$headings-line-height:           1.2;
$headings-color:                 $black;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              ($font-size-base * 1.25);
$lead-font-weight:            300;
$small-font-size:             80%;

$mark-bg: theme-color-level(warning, -8);

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .75rem;
$input-btn-line-height:       $line-height-base;
$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       transparent;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;
$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-line-height-sm:    $line-height-sm;
$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-line-height-lg:    $line-height-lg;
$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075);
$btn-active-box-shadow:       inset 0 3px 5px rgba($black,.125);
$btn-link-disabled-color:     $gray-600;
$btn-block-spacing-y:         .5rem;
$btn-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$tooltip-max-width:                 200px;
$tooltip-color:                     $white;
$tooltip-bg:                        $primary;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 3px;
$tooltip-padding-x:                 8px;
$tooltip-margin:                    0;


$tooltip-arrow-width:               12px;
$tooltip-arrow-height:              8px;
$tooltip-arrow-color:               $tooltip-bg;


$dropdown-link-hover-bg:            $light-gray;


// Form validation
$form-feedback-valid-color:         $success;
$form-feedback-invalid-color:       $danger;

// Pagination

$pagination-padding-y:              .5rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           $pagination-padding-y;
$pagination-padding-x-lg:           $pagination-padding-x * 1.5;
$pagination-line-height:            1.25;

$pagination-color:                  $link-color;
$pagination-bg:                     $white;
$pagination-border-width:           0;
$pagination-border-color:           transparent;

$pagination-hover-color:            $link-hover-color;
$pagination-hover-bg:               $white;
$pagination-hover-border-color:     #ddd;

$pagination-active-color:           $white;
$pagination-active-bg:              $brand-blue;
$pagination-active-border-color:    $brand-blue;

$pagination-disabled-color:         $gray;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray;




//
//
// // Tables
// //
// // Customizes the `.table` component with basic values, each used across all table variations.
//
// $table-cell-padding:          .75rem;
// $table-cell-padding-sm:       .3rem;
//
// $table-bg:                    transparent;
// $table-accent-bg:             rgba($black,.05);
// $table-hover-bg:              rgba($black,.075);
// $table-active-bg:             $table-hover-bg;
//
// $table-border-width:          $border-width;
$table-border-color:             $light-gray;
//
// $table-head-bg:               $gray-200;
// $table-head-color:            $gray-700;
//
// $table-dark-bg:               $gray-900;
// $table-dark-accent-bg:        rgba($white, .05);
// $table-dark-hover-bg:         rgba($white, .075);
// $table-dark-border-color:     lighten($gray-900, 7.5%);
// $table-dark-color:            $body-bg;
//
//
//
// $form-text-margin-top:                  .25rem;
//
// $form-check-margin-bottom:              .5rem;
// $form-check-input-gutter:               1.25rem;
// $form-check-input-margin-y:             .25rem;
// $form-check-input-margin-x:             .25rem;
//
// $form-check-inline-margin-x:            .75rem;
//
// $form-group-margin-bottom:              1rem;
//
// $input-group-addon-color:               $input-color;
// $input-group-addon-bg:                  $gray-200;
// $input-group-addon-border-color:        $input-border-color;
//
// $custom-control-gutter:                 1.5rem;
// $custom-control-spacer-y:               .25rem;
// $custom-control-spacer-x:               1rem;
//
// $custom-control-indicator-size:         1rem;
// $custom-control-indicator-bg:           #ddd;
// $custom-control-indicator-bg-size:      50% 50%;
// $custom-control-indicator-box-shadow:   inset 0 .25rem .25rem rgba($black,.1);
//
// $custom-control-indicator-disabled-bg:          $gray-200;
// $custom-control-description-disabled-color:     $gray-600;
//
// $custom-control-indicator-checked-color:        $white;
// $custom-control-indicator-checked-bg:           theme-color("primary");
// $custom-control-indicator-checked-box-shadow:   none;
//
// $custom-control-indicator-focus-box-shadow:     0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
//
// $custom-control-indicator-active-color:         $white;
// $custom-control-indicator-active-bg:            lighten(theme-color("primary"), 35%);
// $custom-control-indicator-active-box-shadow:    none;
//
// $custom-checkbox-indicator-border-radius:       $border-radius;
// $custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
//
// $custom-checkbox-indicator-indeterminate-bg:    theme-color("primary");
// $custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
// $custom-checkbox-indicator-icon-indeterminate:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23");
// $custom-checkbox-indicator-indeterminate-box-shadow: none;
//
// $custom-radio-indicator-border-radius:          50%;
// $custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23");
//
// $custom-select-padding-y:           .375rem;
// $custom-select-padding-x:          .75rem;
// $custom-select-height:              $input-height;
// $custom-select-indicator-padding:   1rem; // Extra padding to account for the presence of the background-image based indicator
// $custom-select-line-height:         $input-btn-line-height;
// $custom-select-color:               $input-color;
// $custom-select-disabled-color:      $gray-600;
// $custom-select-bg:                  $white;
// $custom-select-disabled-bg:         $gray-200;
// $custom-select-bg-size:             8px 10px; // In pixels because image dimensions
// $custom-select-indicator-color:     #333;
// $custom-select-indicator:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23");
// $custom-select-border-width:        $input-btn-border-width;
// $custom-select-border-color:        $input-border-color;
// $custom-select-border-radius:       $border-radius;
//
// $custom-select-focus-border-color:  lighten(theme-color("primary"), 25%);
// $custom-select-focus-box-shadow:    inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5);
//
// $custom-select-font-size-sm:        75%;
// $custom-select-height-sm:           $input-height-sm;
//
// $custom-file-height:                $input-height;
// $custom-file-width:                 14rem;
// $custom-file-focus-box-shadow:      0 0 0 .075rem $white, 0 0 0 .2rem theme-color("primary");
//
// $custom-file-padding-y:             $input-btn-padding-y;
// $custom-file-padding-x:             $input-btn-padding-x;
// $custom-file-line-height:           $input-btn-line-height;
// $custom-file-color:                 $input-color;
// $custom-file-bg:                    $input-bg;
// $custom-file-border-width:          $input-btn-border-width;
// $custom-file-border-color:          $input-border-color;
// $custom-file-border-radius:         $input-border-radius;
// $custom-file-box-shadow:            $input-box-shadow;
// $custom-file-button-color:          $custom-file-color;
// $custom-file-button-bg:             $input-group-addon-bg;
// $custom-file-text: (
//   placeholder: (
//     en: "Choose file..."
//   ),
//   button-label: (
//     en: "Browse"
//   )
// );
//
//
//
//
// // Dropdowns
// //
// // Dropdown menu container and contents.
//
// $dropdown-min-width:                10rem;
// $dropdown-padding-y:                .5rem;
// $dropdown-spacer:                   .125rem;
// $dropdown-bg:                       $white;
// $dropdown-border-color:             rgba($black,.15);
// $dropdown-border-width:             $border-width;
// $dropdown-divider-bg:               $gray-200;
// $dropdown-box-shadow:               0 .5rem 1rem rgba($black,.175);
//
// $dropdown-link-color:               $gray-900;
// $dropdown-link-hover-color:         darken($gray-900, 5%);
// $dropdown-link-hover-bg:            $gray-100;
//
// $dropdown-link-active-color:        $component-active-color;
// $dropdown-link-active-bg:           $component-active-bg;
//
// $dropdown-link-disabled-color:      $gray-600;
//
// $dropdown-item-padding-y:           .25rem;
// $dropdown-item-padding-x:           1.5rem;
//
// $dropdown-header-color:             $gray-600;
//
//
// // Z-index master list
// //
// // Warning: Avoid customizing these values. They're used for a bird's eye view
// // of components dependent on the z-axis and are designed to all work together.
//
// $zindex-dropdown:                   1000;
// $zindex-sticky:                     1020;
// $zindex-fixed:                      1030;
// $zindex-modal-backdrop:             1040;
// $zindex-modal:                      1050;
// $zindex-popover:                    1060;
// $zindex-tooltip:                    1070;
//
// // Navs
//
// $nav-link-padding-y:                .5rem;
// $nav-link-padding-x:                1rem;
// $nav-link-disabled-color:           $gray-600;
//
// $nav-tabs-border-color:             #ddd;
// $nav-tabs-border-width:             $border-width;
// $nav-tabs-border-radius:            $border-radius;
// $nav-tabs-link-hover-border-color:  $gray-200;
// $nav-tabs-link-active-color:        $gray-700;
// $nav-tabs-link-active-bg:           $body-bg;
// $nav-tabs-link-active-border-color: #ddd;
//
// $nav-pills-border-radius:           $border-radius;
// $nav-pills-link-active-color:       $component-active-color;
// $nav-pills-link-active-bg:          $component-active-bg;
//
// // Navbar
//
// $navbar-padding-y:                  ($spacer / 2);
// $navbar-padding-x:                  $spacer;
//
// $navbar-brand-font-size:            $font-size-lg;
// // Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   ($font-size-base * $line-height-base + $nav-link-padding-y * 2);
// $navbar-brand-height:               $navbar-brand-font-size * $line-height-base;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2;
//
// $navbar-toggler-padding-y:          .25rem;
// $navbar-toggler-padding-x:          .75rem;
// $navbar-toggler-font-size:          $font-size-lg;
// $navbar-toggler-border-radius:      $btn-border-radius;
//
// $navbar-dark-color:                 rgba($white,.5);
// $navbar-dark-hover-color:           rgba($white,.75);
// $navbar-dark-active-color:          $white;
// $navbar-dark-disabled-color:        rgba($white,.25);
// $navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
// $navbar-dark-toggler-border-color:  rgba($white,.1);
//
// $navbar-light-color:                rgba($black,.5);
// $navbar-light-hover-color:          rgba($black,.7);
// $navbar-light-active-color:         rgba($black,.9);
// $navbar-light-disabled-color:       rgba($black,.3);
// $navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
// $navbar-light-toggler-border-color: rgba($black,.1);
//
//
//
// // Jumbotron
//
// $jumbotron-padding:                 2rem;
// $jumbotron-bg:                      $gray-200;
//
//
// // Cards
//
// $card-spacer-y:                     .75rem;
// $card-spacer-x:                     1.25rem;
// $card-border-width:                 $border-width;
// $card-border-radius:                $border-radius;
// $card-border-color:                 rgba($black,.125);
// $card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
// $card-cap-bg:                       rgba($black, .03);
// $card-bg:                           $white;
//
// $card-img-overlay-padding:          1.25rem;
//
// $card-group-margin:                 ($grid-gutter-width / 2);
// $card-deck-margin:                  $card-group-margin;
//
// $card-columns-count:                3;
// $card-columns-gap:                  1.25rem;
// $card-columns-margin:               $card-spacer-y;
//
//
// // Tooltips
//
//
//
// // Popovers
//
// $popover-bg:                        $white;
// $popover-max-width:                 276px;
// $popover-border-width:              $border-width;
// $popover-border-color:              rgba($black,.2);
// $popover-box-shadow:                0 .25rem .5rem rgba($black,.2);
//
// $popover-header-bg:                 darken($popover-bg, 3%);
// $popover-header-color:              $headings-color;
// $popover-header-padding-y:          .5rem;
// $popover-header-padding-x:          .75rem;
//
// $popover-body-color:                $body-color;
// $popover-body-padding-y:            $popover-header-padding-y;
// $popover-body-padding-x:            $popover-header-padding-x;
//
// $popover-arrow-width:               .8rem;
// $popover-arrow-height:              .4rem;
// $popover-arrow-color:               $popover-bg;
//
// $popover-arrow-outer-color:         fade-in($popover-border-color, .05);
//
//
// // Badges
//
// $badge-font-size:                   75%;
// $badge-font-weight:                 $font-weight-bold;
// $badge-padding-y:                   .25em;
// $badge-padding-x:                   .4em;
// $badge-border-radius:               $border-radius;
//
// $badge-pill-padding-x:              .6em;
// // Use a higher than normal value to ensure completely rounded edges when
// // customizing padding or font-size on labels.
// $badge-pill-border-radius:          10rem;
//
//
// // Modals
//
// // Padding applied to the modal body
// $modal-inner-padding:               15px;
//
// $modal-dialog-margin:               10px;
// $modal-dialog-margin-y-sm-up:       30px;
//
// $modal-title-line-height:           $line-height-base;
//
// $modal-content-bg:                  $white;
// $modal-content-border-color:        rgba($black,.2);
// $modal-content-border-width:        $border-width;
// $modal-content-box-shadow-xs:       0 3px 9px rgba($black,.5);
// $modal-content-box-shadow-sm-up:    0 5px 15px rgba($black,.5);
//
// $modal-backdrop-bg:                 $black;
// $modal-backdrop-opacity:            .5;
// $modal-header-border-color:         $gray-200;
// $modal-footer-border-color:         $modal-header-border-color;
// $modal-header-border-width:         $modal-content-border-width;
// $modal-footer-border-width:         $modal-header-border-width;
// $modal-header-padding:              15px;
//
// $modal-lg:                          800px;
// $modal-md:                          500px;
// $modal-sm:                          300px;
//
// $modal-transition:                  transform .3s ease-out;
//
//
// // Alerts
// //
// // Define alert colors, border radius, and padding.
//
// $alert-padding-y:                   .75rem;
// $alert-padding-x:                   1.25rem;
// $alert-margin-bottom:               1rem;
// $alert-border-radius:               $border-radius;
// $alert-link-font-weight:            $font-weight-bold;
// $alert-border-width:                $border-width;
//
//
// // Progress bars
//
// $progress-height:                   1rem;
// $progress-font-size:                ($font-size-base * .75);
// $progress-bg:                       $gray-200;
// $progress-border-radius:            $border-radius;
// $progress-box-shadow:               inset 0 .1rem .1rem rgba($black,.1);
// $progress-bar-color:                $white;
// $progress-bar-bg:                   theme-color("primary");
// $progress-bar-animation-timing:     1s linear infinite;
// $progress-bar-transition:           width .6s ease;
//
// // List group
//
// $list-group-bg:                     $white;
// $list-group-border-color:           rgba($black,.125);
// $list-group-border-width:           $border-width;
// $list-group-border-radius:          $border-radius;
//
// $list-group-item-padding-y:         .75rem;
// $list-group-item-padding-x:         1.25rem;
//
// $list-group-hover-bg:               $gray-100;
// $list-group-active-color:           $component-active-color;
// $list-group-active-bg:              $component-active-bg;
// $list-group-active-border-color:    $list-group-active-bg;
//
// $list-group-disabled-color:         $gray-600;
// $list-group-disabled-bg:            $list-group-bg;
//
// $list-group-action-color:           $gray-700;
// $list-group-action-hover-color:     $list-group-action-color;
//
// $list-group-action-active-color:    $body-color;
// $list-group-action-active-bg:       $gray-200;
//
//
// // Image thumbnails
//
// $thumbnail-padding:                 .25rem;
// $thumbnail-bg:                      $body-bg;
// $thumbnail-border-width:            $border-width;
// $thumbnail-border-color:            #ddd;
// $thumbnail-border-radius:           $border-radius;
// $thumbnail-box-shadow:              0 1px 2px rgba($black,.075);
// $thumbnail-transition:              all .2s ease-in-out;
//
//
// // Figures
//
// $figure-caption-font-size:          90%;
// $figure-caption-color:              $gray-600;
//
//
// // Breadcrumbs
//
// $breadcrumb-padding-y:              .75rem;
// $breadcrumb-padding-x:              1rem;
// $breadcrumb-item-padding:           .5rem;
//
// $breadcrumb-margin-bottom:          1rem;
//
// $breadcrumb-bg:                     $gray-200;
// $breadcrumb-divider-color:          $gray-600;
// $breadcrumb-active-color:           $gray-600;
// $breadcrumb-divider:                "/";
//
//
// // Carousel
//
// $carousel-control-color:            $white;
// $carousel-control-width:            15%;
// $carousel-control-opacity:          .5;
//
// $carousel-indicator-width:          30px;
// $carousel-indicator-height:         3px;
// $carousel-indicator-spacer:         3px;
// $carousel-indicator-active-bg:      $white;
//
// $carousel-caption-width:            70%;
// $carousel-caption-color:            $white;
//
// $carousel-control-icon-width:       20px;
//
// $carousel-control-prev-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
// $carousel-control-next-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");
//
// $carousel-transition:               transform .6s ease;
//
//
// // Close
//
// $close-font-size:                   $font-size-base * 1.5;
// $close-font-weight:                 $font-weight-bold;
// $close-color:                       $black;
// $close-text-shadow:                 0 1px 0 $white;
//
// // Code
//
// $code-font-size:                    90%;
// $code-padding-y:                    .2rem;
// $code-padding-x:                    .4rem;
// $code-color:                        #bd4147;
// $code-bg:                           $gray-100;
//
// $kbd-color:                         $white;
// $kbd-bg:                            $gray-900;
//
// $pre-color:                         $gray-900;
// $pre-scrollable-max-height:         340px;
