.m-image-slider {
  .owl-next,
  .owl-prev {
    position: absolute;
    top: 50%;
    margin-top: -1.5rem;
    color: $light-blue;
    transition: all, .5s;
    font-size: 2rem;

    &:hover {
      color: $blue;
    }
  }

  .owl-prev {
    left: 2rem;
  }

  .owl-next {
    right: 2rem;
  }

  .owl-dots {
    position: absolute;
    bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
  }

  .owl-dot {
    width: 1rem;
    height: 1rem;
    background: transparent;
    border-radius: 50%;
    transition: all, .5s;
    margin-right: .5rem;
    border: 1px solid $white;

    &:last-child {
      margin: 0;
    }

    &.active,
    &:hover {
      background: $light-blue;
      border: 1px solid $light-blue;
      transition: all, .25s;
    }
  }
}
