.o-checkout-header {
  display: flex;
  background: $white;
  align-items: center;
  flex-direction: column;
  &__inner {
    @extend .container;
    display: flex;
    align-items: center;
    min-height: 4rem;
    margin-top: -.15rem;
    padding-bottom: .15rem;
    @include media-breakpoint-up(sm) {
      min-height: 7.1rem;
    }

    @media only screen
    and (min-device-width: 320px)
    and (max-device-width: 568px)
    and (orientation: landscape) {
    }

  }

  &__progress {
    background: $light-gray;
    width: 100%;

  }

  &--noProgress {
    //min-height: 7.9em;
    //padding: 0.5rem 1rem;
    //.o-checkout-header__inner {
    //  padding-top: 1rem;
    //  padding-bottom: 1rem;
    //
    //}
  }

  &--e4y {
    .o-navbar__brand {
      img {
        @extend .img-fluid;
        width: 120px;
        @include media-breakpoint-up(lg) {
          width: 160px;
        }
      }
    }
  }

  &--customBrand {
    .o-navbar__brand {
      img {
        max-height: 3.5rem;
        max-width: 10rem;
        transition: all .25s ease-in-out;
      }

    }
  }

}



