.o-hero {
  // elements
  &__inner {
    @extend .container;
    padding: 0;
    max-width: map-get($container-max-widths, hero);
  }

  // TODO: Move into seperate component some time
  &__slider {
    &.owl-carousel {
      // carousel navigation
      .owl-next,
      .owl-prev {
        position: absolute;
        top: 50%;
        margin-top: -1.5rem;
        color: $light-blue;
        transition: all, .5s;
        font-size: 2rem;

        &:hover {
          color: $blue;
        }
      }

      .owl-prev {
        left: 2rem;
      }

      .owl-next {
        right: 2rem;
      }

      .owl-dots {
        position: absolute;
        bottom: 3rem;
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 2;
      }

      .owl-dot {
        width: 1rem;
        height: 1rem;
        background: transparent;
        border-radius: 50%;
        transition: all, .5s;
        margin-right: .5rem;
        border: 1px solid $dark-gray;

        &:last-child {
          margin: 0;
        }

        &.active,
        &:hover {
          background: $dark-gray;
          border: 1px solid $dark-gray;
          transition: all, .25s;
        }
      }
    }

    &-item {
      overflow: hidden;
      position: relative;
      max-height: 90vh;
      background: $gradient-blue;

      @include media-breakpoint-up(md) {
        max-height: 50vh;
        min-height: 750px;
      }


      &-image {
        // optional overlay

        img {
          height: auto !important;
          @include media-breakpoint-up(md) {
            animation: kenburns 30s infinite;
          }
          @include media-breakpoint-down(sm) {
            height: 60vh;
          }
        }

        video {
          left: 50%;
          min-height: 100vh;
          min-width: 100%;
          position: relative;
          transform: translate(-50%, -10%);
          @include media-breakpoint-up(md) {
            min-height: 60vh;
          }
          &.lowered {
            top: 100px;
          }
        }
      }

      &-content {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        align-items: baseline;

        display: flex;
        justify-content: center;
        z-index: 2;

        @include media-breakpoint-up(sm) {
          align-items: center;
          padding: 0;
        }
        &-inner {
          @extend .container;
          transform: scale(.85);
          @include media-breakpoint-up(md) {
            transform: scale(1);
          }

        }

      }

    }
  }

  // modifiers
  &--slider {
  }

  &--curved {

    .o-hero__slider-item-content-inner {
      padding: 2vh 0 2vh 0;
      @include media-breakpoint-up(md) {
        padding: 50px 0 100px 0;
      }
    }
    .o-hero__slider-item {
      @include media-breakpoint-up(md) {
        min-height: 550px;
      }
    }



    // curved bottom edge
    .o-hero__slider-item-image:after {
      content: " ";
      display: block;
      background: url("../img/hero-edge.svg");
      position: absolute;
      bottom: -4px;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: 103%;
      background-repeat: no-repeat;
      background-position: bottom;
    }
  }

  &--overlay {
    &-blue {
      .o-hero__slider-item-image {
        filter: blur(0px);
        transform: scale(1.1);
        &:before {
          content: " ";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: $gradient-blue;
          opacity: .75;
        }

      }
    }

  }
}

// animation
@keyframes kenburns {
  0% {
    transform: scale(1.2) translate3d(0px, 30px, 0px);
  }

  50% {
    transform: scale(1) translate3d(0px, 0px, 0px);
  }

  100% {
    transform: scale(1.2) translate3d(0px, 30px, 0px);
  }
}
