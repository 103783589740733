
.t-e4y {

  &__footer {
    z-index: 1;
  }
  .o-footer {
    .container {
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap !important;
      }
    }
    &__bottom {
      background-color: $white !important;
      color: $black !important;
      @include media-breakpoint-down(sm) {
        padding-top: .5rem;
        padding-bottom: .25rem;
      }

      &-right, .nav-link {
        @include media-breakpoint-down(sm) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .nav-item {
        display: flex;
      }
      a.nav-link {
        color: $blue;
        align-self: center;
        @include media-breakpoint-down(sm) {
          padding-left: .5rem;
          padding-right: .5rem;
        }

        &:hover {
          color: $red;
        }
      }
      a.a-button {
        color: $brand-blue;
        margin-right: 1rem;
        @include media-breakpoint-down(sm) {
          padding: .1em .5em;
          margin-left: .5rem;
          margin-right: .5rem;
        }

        &:hover {
        color: $white;
      }
    }

    }
    &__powered {
      transform: scale(.65);
      @include media-breakpoint-up(md) {
        transform: scale(1);
      }

    }

  }
}