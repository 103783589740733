.m-price-calculator {
    @include mdElevation(6);
    border-radius: $border-radius;
    // background: $light-gray;

    &__header {
        background: rgba(255, 255, 255, 0.7);
        padding: .75rem 0 .75rem 1.25rem;
        border-radius: $border-radius $border-radius 0 0;
        border-bottom: 1px solid $medium-gray;
    }

    &__body {
        padding: 1.25rem;
        background: $light-gray;
        border-radius: 0 0 $border-radius $border-radius;
    }

    &__heading {
        @extend .h4;
        margin-bottom: 0;
        color: $black;

        .a-icon {
            color: $black;
            display: inline-block;
            position: relative;
            top: .15em;
            margin-right: .5em;
        }

    }

    .form-group {
        margin-bottom: .25rem;

        label {
            color: $dark-gray;

            .a-icon-info {
                color: $black;
                display: inline-block;
                position: relative;
                top: .15em;
                left: .25em;
            }
        }

        input {
            border-radius: $border-radius;
            font-size: 1.5rem;
            margin: 0;
            padding-bottom: .25rem;
            &.form-control:focus {
                border-color: $gray-400;
            }
        }

        select {
            border-radius: $border-radius;
            font-size: 1.5rem;
            margin: 0;
            padding-bottom: .25rem;
            &.form-control:focus {
                border-color: $gray-400;
            }
        }

    }

    &__consumption {
        display: flex;
        flex-direction: column;
        border: 1px solid #ced4da;
        background: $white;
        border-radius: $border-radius;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }

        &-buttons {
            display: flex;
            align-items: center;

            .btn-group {
                display: flex;
                border-bottom: 1px solid $medium-gray;
                padding-bottom: .375rem;
                padding-top: .375rem;
                flex-grow: 1;
                justify-content: center;
                @include media-breakpoint-up(sm) {
                    padding-bottom: 0;
                    border-bottom: 0;
                    padding-top: 0;
                }

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: .15rem .60rem;
                    border: 0;
                    border-radius: 0;
                    background: transparent;
                    flex-grow: 1;
                    border: 0;
                    border-right: 1px solid $medium-gray;
                    min-width: 2.5rem;

                    &:last-child {
                        border-right: 0;
                        @include media-breakpoint-up(sm) {
                            border-right: 1px solid $medium-gray;
                        }
                    }

                    svg {
                        width: auto;
                        height: 1.5rem;
                        fill: $gray;
                    }

                    &:hover {
                        svg {
                            fill: $black;
                        }
                    }

                    &.selected {
                        svg {
                            fill: $black;
                        }
                    }
                }
            }

        }

        &-value {
            position: relative;
            width: 100%;

            input {
                border: 0;
                text-align: right;
                padding-right: 4.25rem;
                margin: 0;

                &[name*=".hidden"] {
                    opacity: 0;
                    z-index: -1;
                    position: absolute;
                }
            }

            span {
                @extend .small;
                position: absolute;
                right: .5rem;
                top: .65rem;
                pointer-events: none;
                z-index: 10;
                padding-top: .5rem;
                color: $medium-gray;
            }
        }
    }

    &__cta {
        margin-top: 1.25rem;

        .a-icon {
            position: relative;
            top: 1px;
        }

    }

}
