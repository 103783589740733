.m-tabs {
    .card-header {
        padding-top: .5rem;
    }

    .nav-tabs {
        a {
            border-radius: $border-radius $border-radius 0 0;
            &.active {
                color: $brand-blue;
            }
        }
    }

    .nav-pills {
        a {
            border-radius: $border-radius;
            &.active {
                background: $brand-blue;
            }
        }
    }
}
