.o-form {
  label {
    @extend .small;
    margin-bottom: .25em;
  }

  .form-control {
    border-radius: $border-radius;

    &:focus {
      background: lighten($light-blue, 48%);
    }
  }

  .was-validated {
    .custom-control-input.is-valid ~ .custom-control-label::before,
    .custom-control-input:valid ~ .custom-control-label::before {
      background: $success;
    }

    .form-control {
      &:invalid {
        background: lighten($brand-red, 55%);
      }

      &:valid {
        background: lighten($success, 55%);
      }
    }

    .feedback-text {
    }

    & .invalid-feedback i,
    & .valid-feedback i {
      display: block;
      position: absolute;
      width: auto;
      float: right;

    }

    .form-control.form-control-lg:invalid ~ .invalid-feedback i,
    .form-control.form-control-lg:valid ~ .valid-feedback i {
      top: 1em;
      right: 1em;
      font-size: 1.25em;
    }

    .form-control:invalid ~ .invalid-feedback i,
    .form-control:valid ~ .valid-feedback i {
      top: 2.25em;
      right: .75em;
      font-size: 1.25em;
    }

    .form-control-sm:invalid ~ .invalid-feedback i,
    .form-control-sm:valid ~ .valid-feedback i {
      top: 2.5em;
      right: 1.5em;
      font-size: 1.25em;
    }

    .input-group .form-control.form-control-lg:invalid ~ .invalid-feedback i,
    .input-group .form-control.form-control-lg:valid ~ .valid-feedback i {
      top: .75em;
      right: 1em;
      font-size: 1.25em;
    }

    .input-group .form-control:invalid ~ .invalid-feedback i,
    .input-group .form-control:valid ~ .valid-feedback i {
      top: .35em;
      right: 1em;
      font-size: 1.25em;
    }

    .input-group .form-control-sm:invalid ~ .invalid-feedback i,
    .input-group .form-control-sm:valid ~ .valid-feedback i {
      top: .35em;
      right: 1em;
      font-size: 1.25em;
    }

  }

  h4 {
    i {
      font-size: 14.4px;
    }
  }

  .a-radio-button {
    label {
      background-color: lighten($secondary, 5%);
      border-color: lighten($secondary, 5%);
      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
      }
      &:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
      &:before {
        font-family: leu-icons;
        content: "\43";
        margin-right: .5rem;
        font-size: .75rem;
        line-height: 1em;
        position: relative;
        top: 2px;

      }
      &.active {
        background-color: $brand-blue !important;
        border-color: $brand-blue !important;
        &:before {
          content: "\44";
        }
      }
    }

  }
label:empty {
  display: none;
}

}

.custom-control-label::before, .custom-control-label::after, .custom-file-label {
  border-radius: $border-radius;
}

.custom-file {
  cursor: pointer;
}
.custom-file-label {
  padding-top: .5rem;
  cursor: pointer;
  &:after {
    padding-top: .5rem;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    content: "Durchsuchen";
    background: $secondary;
    color: $white;
    cursor: pointer;
  }
}