.a-table {
    table {
        @extend .table;
        @extend .table-bordered;
        margin: 0;
        thead {
            background: $light-gray;
            th {
                border-bottom-width: 0;
            }
        }
        td {
            vertical-align: middle;
        }
    }


    &--dark-blue {
        table {
            thead {
                background: $dark-blue;
                color: $white;
            }
        }
    }

    &--dark-red {
        table {
            thead {
                background: $dark-red;
                color: $white;
            }
        }

    }

    &--listing {
        table {
            border: none;
            td {
                border: none;
                padding-left: 0;
                padding: .5rem 0 .5rem 0;
                border-bottom: 1px solid darken($light-gray, 5%);

            }
            tr:first-child {
                td {
                    padding-top: 0;
                    padding-top: 0;
                }
            }
            tr:last-child {
                td {
                    border-bottom: none;
                    padding-bottom: 0;

                }
            }

        }

    }
}

.card {
    .a-table {
        table {
            border: none;
        }
        tr:first-child {
            border-top: none;
            td {
                border-top: none;
            }
        }
        tr:last-child {
            border: none;
            td {
                border-bottom: none;
            }
        }
        td:first-child {
            border-left: none;
        }
        td:last-child {
            border-right: none;
        }
    }
}