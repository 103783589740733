// link atom

a {
    &:hover {
        color: $brand-red;
        &:after, &:before {
            color: $brand-red;
        }
    }
}
a.a-link {

    // align icons
    // display: flex;

    &--with-arrow {
        &:before {
            font-family: "leu-icons";
            content: "\74";
            font-size: 1em;
            margin-right: .25em;
            position: relative;
            display: inline-block;
            top: .15em;
        }
    }
    &--light {
        color: $white;
        text-decoration: underline;
    }
}

// Automatically add icon to external links
a[target^="_blank"]:after {
    font-family: "leu-icons";
    content: "\69";
    font-size: .75em;
    margin-left: .25em;
}
a[href ^= "mailto"]:before {
    font-family: "leu-icons";
    content: "\6d";
    font-size: 1em;
    margin-right: .25em;
    position: relative;
    display: inline-block;
    top: .1em;
}
a[href ^= "tel"]:before {
    font-family: "leu-icons";
    content: "\42";
    font-size: 1em;
    margin-right: .25em;
    position: relative;
    display: inline-block;
    top: .15em;
}

form {
    a[target^="_blank"]:after {
        display: none;
    }
}
