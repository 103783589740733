.o-navbar--b2b {
  .o-navbar__brand {
    padding-top: .55rem;
    margin-right: 1.5rem;
    @include media-breakpoint-up(lg) {
      padding-top: .9rem;
    }



    img {
      @extend .img-fluid;
      width: 120px;
      @include media-breakpoint-up(lg) {
        width: 160px;
      }
    }
  }
  .o-navbar__hotline {
    font-size: .75rem;
    @include media-breakpoint-up(xl) {
      font-size: 1rem;

    }
  }
  .o-navbar__item-link {
    padding: 0 .25rem;
    @include media-breakpoint-up(xl) {
      padding: 0 1.5rem ;
    }

  }

  &.is-sticky {
    .o-navbar__brand {
      margin-top: -.25rem;
    }
  }

}