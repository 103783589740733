.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius;
    &-dismissible {
        padding-right: 0;
    }
    p {
        flex-grow: 1;
        margin:0;
    }
    i {
        display: inline-block;
        position: relative;
        font-size: 2em;
        top: .15em;
        margin-right: .25em;
    }
    .close {
        position: relative;
        align-self: center;

    }
}
