.a-button {
    @extend .btn;
    padding: .375em .75em;
    border-radius: $border-radius;
    white-space: normal;

    &:focus {
        box-shadow: none;
    }

    // style modifiers

    &--primary {
        @extend .btn-primary;
        background: transparent;
        border: 1px solid $light-blue;
        color: $light-blue;

        &:hover {
            background: $light-blue;
            color: $white;
            border: 1px solid $light-blue;
        }

        &-solid {
            background: $light-blue;
            border: 1px solid $light-blue;
            color: $white;

            &:hover {
                background: transparent;
                color: $light-blue;
            }
        }
    }

    &--primary-light {
        @extend .btn-primary;
        background: transparent;
        color: $white;
        border: 1px solid $white;

        &:hover {
            background: $white;
            color: $light-blue;
            border: 1px solid $white;
        }

        &-solid {
            background: $white;
            border: 1px solid $white;
            color: $light-blue;

            &:hover {
                background: transparent;
                color: $white;
            }
        }
    }

    &--secondary {
        @extend .btn-secondary;
        background: transparent;
        color: $gray;

        &:hover {
            background: $gray;
            border: 1px solid $gray;
            color: $white;
        }

        &-solid {
            background: $gray;
            border: 1px solid $gray;
            color: $white;

            &:hover {
                background: transparent;
                color: $gray;
                border: 1px solid $gray;
            }
        }
    }

    &--secondary-light {
        @extend .btn-secondary;
        background: $white;
        color: $gray;

        &:hover {
            background: transparent;
            color: $white;
            border: 1px solid $white;
        }

        &-solid {
            background: transparent;
            border: 1px solid $white;
            color: $white;

            &:hover {
                background: $white;
                color: $gray;
            }
        }
    }

    &--cta {
        background: transparent;
        color: $cta;
        border: 1px solid $cta;

        &:hover {
            background: $cta;
            border: 1px solid $cta;
            color: $white;
        }

        &-solid {
            background: $cta;
            border: 1px solid $cta;
            color: $white;

            &:hover {
                background: transparent;
                color: $cta;
                border: 1px solid $cta;
            }
            &-fill {
                &:hover {
                    border-color: $white;
                    color: $white;
                }
            }
        }
    }

    &--cta-light {
        background: $white;
        color: $cta;

        &:hover {
            background: transparent;
            color: $white;
            border: 1px solid $white;
        }

        &-solid {
            background: transparent;
            border: 1px solid $white;
            color: $white;

            &:hover {
                background: $white;
                color: $cta;
            }
        }
    }

    &--success {
        @extend .btn-success;
        background: transparent;
        color: $success;
        border: 1px solid $success;

        &:hover {
            background: $success;
            border: 1px solid $success;
            color: $white;
        }

        &-solid {
            background: $success;
            border: 1px solid $success;
            color: $white;

            &:hover {
                background: transparent;
                color: $success;
                border: 1px solid $success;
            }
            &-fill {
                &:hover {
                    background: $white;
                }
            }

        }
    }

    &--success-light {
        @extend .btn-success;
        background: $white;
        color: $success;

        &:hover {
            background: transparent;
            color: $white;
            border: 1px solid $white;
        }

        &-solid {
            background: transparent;
            border: 1px solid $white;
            color: $white;

            &:hover {
                background: $white;
                color: $success;
            }
        }
    }
    &--link {
        padding: 0;
        &-light {
            color: $light;
            text-decoration: underline;
        }
    }

    // size modifiers

    &--xs {

        font-size: .6rem;
        padding: .2em .25em .15em;

    }
    &--sm {
        font-size: .75rem;
    }

    &--md {
        font-size: 1rem;

    }

    &--lg {
        font-size: 1.5rem;

    }

    &--xl {
        font-size: 1.75rem;
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--icon {

        i {
            font-size: .75em;
            line-height: .75;
        }

        &-left {
            i:after {
                content: " ";
                white-space: pre;
            }
        }

        &-right {
            span:after {
                content: " ";
                white-space: pre;
            }
        }

    }

}
