@import "e4y/_partials/global__checkout-header";
//@import "e4y/_partials/b2b__checkout-header";
//@import "e4y/_partials/b2b__checkout-header";
@import "e4y/_partials/b2e__footer";
@import "e4y/_partials/b2b_o-navbar";

.company-id {
  font-size: 1.5rem;
  font-family: $font-family-monospace;
  &--small {
    font-size: 1rem;
  }
}
.employee-url {
  font-size: 1rem;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }

}


.t-e4y {
  .m-pricing-table__item-header img {
    max-height: 45px;
  }
}
