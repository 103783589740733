.hidden {
  display:none;
}

i[data-target] {
  cursor:pointer;
}

.o-hero {
  .text-shadow {
    text-shadow: 0 0 20px #7F7F7F;
  }
}

.o-navbar {

  .navbar-nav {
    .o-navbar__item {
      font-weight: 600;

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }

      &-link {
        @include media-breakpoint-up(md) {
          padding-right: 0.3rem;
          padding-left: 0.3rem;
        }
      }
    }
  }
}

.o-social-panel {
  a[target^="_blank"]:after {
    content:"";
    margin:0;
  }
}

.o-section__inner {
  .text-light {
    > * {
      color:$white;
    }
  }
}

.o-footer__address {
  font-size:100%;
}


// Hero responsive adjustments

.hero-text {
  margin-top: 201px;
}

@include media-breakpoint-down(lg) {
  .owl-carousel .owl-item img {
    width: 151%
  }
  .hero-text {
    margin-top: 177px;
  }
}
@include media-breakpoint-down(sm) {
  .owl-carousel .owl-item img {
    width: auto;
    //height: 70vh !important;
    height: auto;
  }
  .hero-text {
    margin-top: 100px;
  }
}

@include media-breakpoint-down(md) {
  .o-hero__slider-item {
    max-height: 99vh;
  }
  .o-hero__slider-item-content-inner {
    padding-top: 5vh;
    & > .row {
      flex-direction: column;
    }
  }

  .hero-text {
    order: -1;
    padding: 0 !important;
    margin-bottom: 1.5rem;
    margin-top: 0;
    & > p {
      text-align: center !important;
      margin: 0;
    }

  }
}

.o-hero__slider-item-content-inner .h1 {
  margin: 0;
}

@include media-breakpoint-down(sm) {

  .o-hero__slider-item {
    max-height: none;
    background: $white;

  }

  .o-hero__slider-item-image {
    //display: none;
  }

  .o-hero__slider-item-content {
    position: absolute;
  }

  .o-hero__slider-item-content-inner {
    transform: scale(1);
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }


  .hero-text {
    padding: 0 !important;

    & > p {
      text-align: center !important;
      margin: 0;
    }
    .h1 {
      font-size: 2rem;
      //color: $dark-gray!important;
      //text-shadow: none;
    }
    .h2 {
      font-size: 1rem;
      //color: $dark-gray!important;
      //text-shadow: none;
    }
  }

}

@include media-breakpoint-down(xs) {
  .o-hero__slider-item-content-inner {
    padding: 0;
  }

  .hero-text {
    padding: 1rem 2rem !important;
    margin: 0;
  }
  .hero-calculator {
    padding: 0 2rem 1rem 2rem;
  }
}

.a-icon-size--2 {
  font-size:2rem !important;
}
.a-icon-size--3 {
  font-size:3rem !important;
}
.a-icon-size--4 {
  font-size:4rem !important;
}
.a-icon-size--5 {
  font-size:5rem !important;
}
.a-icon-size--6 {
  font-size:6rem !important;
}
.a-icon-size--7 {
  font-size:7rem !important;
}
.a-icon-size--8 {
  font-size:8rem !important;
}
.a-icon-size--9 {
  font-size:9rem !important;
}
.a-icon-size--10 {
  font-size:10rem !important;
}

.a-icon-color--light-blue {
  color:$light-blue !important;
}
.a-icon-color--dark-blue {
  color:$dark-blue !important;
}
.a-icon-color--brand-red {
  color:$brand-red !important;
}
.a-icon-color--light-red {
  color:$light-red !important;
}
.a-icon-color--light-green {
  color:$light-green !important;
}
.a-icon-color--dark-green {
  color:$dark-green !important;
}
.a-icon-color--white {
  color:$white !important;
}
.a-icon-color--light-gray {
  color:$light-gray !important;
}
.a-icon-color--gray {
  color:$gray !important;
}





.m-price-calculator input[name="tx_leu_pi2[postal]"]:focus {
  border-width:2px;
}


.navbar-nav {
  justify-content:center;
  width:100%;

  .o-navbar__item {
    &:last-child {
      .o-navbar__item-link {
        margin-right:0;
      }
    }
  }
}

.o-navbar__hotline {
  margin-left:calc(2.25rem - 4px) !important;
}
