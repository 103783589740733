.m-contact-person {
    &__inner {
        @include mdElevation(4);
        border-radius: $border-radius;
        background: $white;
        padding: 1.25rem;
    }

    &__body {
        display: flex;
        flex-direction: row;
    }

    &__details {
        @extend .small;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &-header {
            @extend .small;
            display: block;
            margin: 0;
        }

        &-name {
            font-weight: bold;
            hyphens: manual;
            display: block;
            margin-bottom: .5rem;

        }

        &-phone {
            &:before {
                color: $light-blue;
                margin-right: .5em !important;
                content: "\42";

                // font-size: .8rem !important;
            }
        }

        &-email {
            display: flex;
            &:before {
                color: $light-blue;
                top: 0em !important;
                margin-right: .5em !important;

            }
        }
    }

    &__image {
        @extend .d-block;
        @extend .d-md-none;
        @extend .d-lg-block;
        max-width: 25%;
        margin-right: 1rem;

        @include media-breakpoint-up(lg) {
            max-width: 40%;
        }

        img {
            @extend .img-fluid;
            border-radius: $border-radius;
        }
    }
}
